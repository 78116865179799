
import { hideModal } from '@/core/helpers/dom';

export default {
  	name: "message",
  	props: {
		id: {
			type: String,
			required: true,
		},
		icon: {
			type: String,
			required: true,
		},
		classModal: {
			type: String,
			default: "",
		},
		bgIcon: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		subtitle: {
			type: String,
			required: true,
		},
		buttonText: {
			type: String,
			default: "Enviar",
		},
		disabledButton: {
			type: Boolean,
			default: false,
		},
		hiddenButton: {
			type: Boolean,
			default: false,
		},
		hideClose: {
			type: Boolean,
			default: false,
		},
		loadingButton: {
			type: Boolean,
			default: false,
		},
 	},

	setup(props){
		function closeModal(){
			const refModal = document.getElementById(props.id);
			hideModal(refModal);
		}

		return {
			closeModal
		}
	},
};
